import { t } from 'i18next'

import type { CouponDto } from '@hcr/api/consumer'

import { DateTimeFormat, LocaleNamespace } from '../../models'
import { dateFormatter } from '../dates'

interface CouponUsedValidity {
  date: string
  label: string
}

export const createCouponUsedValidity = (coupon: CouponDto): CouponUsedValidity => {
  if (!coupon.used_date) {
    throw new Error('used_date is required')
  }

  return {
    date: dateFormatter.format(DateTimeFormat.Standard, coupon.used_date),
    label: t('Used', { ns: LocaleNamespace.CouponStatus }),
  }
}
