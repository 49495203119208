import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { getJson } from '@hcr/api/shared'

import type { ErrorDto, CouponsDto } from '../../dtos'
import { CouponsDtoSchema } from '../../dtos'

export const createCouponsQueryKey = () => ['optimizely', 'coupons']

export const createCouponsQuery =
  (baseUrl: string) =>
  <Data = CouponsDto>(options?: Partial<UseQueryOptions<CouponsDto, ErrorDto, Data>>) =>
    useQuery<CouponsDto, ErrorDto, Data>({
      ...options,
      queryKey: createCouponsQueryKey(),
      queryFn: () =>
        getJson({
          schema: CouponsDtoSchema,
          url: `${baseUrl}/coupons `,
        }),
    })
