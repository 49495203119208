import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { getJson } from '@hcr/api/shared'

import type { CouponDetailsDto, ErrorDto } from '../../dtos'
import { CouponDetailsDtoSchema } from '../../dtos'

interface CouponDetailsQueryParams {
  couponId: string
}

export const createCouponDetailsQueryKey = (params: CouponDetailsQueryParams) => [
  'optimizely',
  'coupon-details',
  params,
]

export const createCouponDetailsQuery =
  (baseUrl: string) =>
  <Data = CouponDetailsDto>(
    params: CouponDetailsQueryParams,
    options?: Partial<UseQueryOptions<CouponDetailsDto, ErrorDto, Data>>
  ) =>
    useQuery<CouponDetailsDto, ErrorDto, Data>({
      ...options,
      queryKey: createCouponDetailsQueryKey(params),
      queryFn: () =>
        getJson({
          schema: CouponDetailsDtoSchema,
          url: `${baseUrl}/coupons/${params.couponId}/details`,
        }),
    })
