import type { CouponDto } from '@hcr/api/consumer'
import type { CouponContentDto } from '@hcr/api/optimizely'
import { isUndefined } from '@hcr/utils'

export const isCouponOf =
  <T extends CouponDto>(coupon: T | undefined) =>
  (couponContent: CouponContentDto): boolean => {
    if (isUndefined(coupon)) {
      return false
    }

    return coupon.coupon_type === couponContent.couponType
  }
