import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Stack, styled, Typography } from '@mui/material'
import type { FC } from 'react'
import type { To } from 'react-router-dom'

import { COLORS, ICONS } from '../../constants'
import { serratedEdgeVerticalCss } from '../../styles'
import { unit } from '../../utils'
import { Chip } from '../data-display'
import { LinkUnstyled } from '../navigation'

export interface CouponProps {
  caption: string
  icon: string
  link: {
    label: string
    title: string
    to: To
  }
  status: {
    label: string
    value: 'Used' | 'Inactive' | 'Valid'
  }
  title: string
}

export const Coupon: FC<CouponProps> = ({ caption, link, icon, status, title }) => {
  return (
    <Container to={link.to}>
      <Header>
        <Typography color={COLORS.sammal} variant='uppercaseExtraSmall'>
          {caption}
        </Typography>

        <Box>
          <Chip component='coupon' text={status.label} variant={status.value} />
        </Box>
      </Header>
      <Content>
        <Stack gap={unit(4)}>
          <Typography variant='headlineS'>{title}</Typography>
          <Stack sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: unit(2.5) }}>
            <Typography variant='body2'>{link.label}</Typography>
            <FontAwesomeIcon icon={ICONS.farArrowRight} fontSize={unit(4)} />
          </Stack>
        </Stack>

        <Box component='img' src={icon} width={unit(23)} height={unit(20)} />
      </Content>
    </Container>
  )
}

const Container = styled(LinkUnstyled)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-height: ${unit(42)};
  padding: ${unit(5)};
  padding-right: ${unit(6)};
  gap: ${unit(4)};
  background-color: ${COLORS.ruoho['60%']};

  &:hover {
    background-color: ${COLORS.ruoho['20%']};
  }

  ${serratedEdgeVerticalCss};
`

const Content = styled(Stack)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${unit(3)};
  width: 100%;
`

const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: ${unit(2)};
`
