import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, styled, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { COLORS, ICONS, LinkUnstyled, unit } from '@hcr/ui'
import { isNotNull, isNotUndefined } from '@hcr/utils'

import { useHoliday } from '../../../../contexts'
import {
  useAccommodationDetailsQuery,
  useDestinationDetailsQuery,
  useIdToken,
  useRoomDetailsQuery,
} from '../../../../hooks'
import { LocaleNamespace, Path } from '../../../../models'
import { createLinkGoogleMaps, createRoomNameString, isOwnerUsage, to } from '../../../../utils'
import { WarningMissingData } from '../../warning-missing-data'
import {
  BookingDates,
  LayoutBookingOverview,
  LayoutBookingOverviewContent,
  LayoutBookingOverviewDivider,
  LayoutBookingOverviewHeader,
  LayoutBookingOverviewSection,
} from '../common'

import { BookingOverviewLoading } from './BookingOverviewLoading'

export const BookingOverview: FC = () => {
  const { t } = useTranslation()
  const idToken = useIdToken()
  const holiday = useHoliday()

  const accommodation = useAccommodationDetailsQuery(
    { idToken: String(idToken), accommodationId: Number(holiday.data.accommodationId) },
    { enabled: isNotNull(idToken) && isNotNull(holiday.data.accommodationId) }
  )

  const destinationDetails = useDestinationDetailsQuery(
    { destinationId: String(holiday.data.destinationId) },
    { enabled: isNotNull(holiday.data.destinationId) }
  )

  const roomDetails = useRoomDetailsQuery(
    { destinationId: String(holiday.data.destinationId), roomId: String(accommodation.data?.room_type_id) },
    { enabled: isNotNull(holiday.data.destinationId) && isNotUndefined(accommodation.data?.room_type_id) }
  )

  if (holiday.isSuccess && accommodation.isSuccess) {
    const destinationDetailsLink = destinationDetails.isSuccess
      ? createLinkGoogleMaps({
          label: destinationDetails.data.address,
          target: 'external',
          url: destinationDetails.data.address,
        })
      : null

    return (
      <>
        {(destinationDetails.isError || roomDetails.isError) && (
          <Box marginBottom={unit(1.5)}>
            <WarningMissingData />
          </Box>
        )}
        <LayoutBookingOverview>
          <LayoutBookingOverviewHeader>
            <Box
              component='img'
              src={destinationDetails.data?.logoUrl}
              width={unit(6.5)}
              height={unit(6.5)}
              marginBottom={unit(3)}
              visibility={destinationDetails.isSuccess ? 'visible' : 'hidden'}
            />
            <Typography variant='bodyM'>{t('booking-overview.your-booking-at')}</Typography>
            <Typography variant='headlineL'>{destinationDetails.data?.name ?? t('booking-overview.resort')}</Typography>
          </LayoutBookingOverviewHeader>
          <LayoutBookingOverviewContent>
            <BookingDates
              checkInDate={accommodation.data.start_date}
              checkInHour={roomDetails.data?.checkInHour}
              checkOutDate={accommodation.data.end_date}
              checkOutHour={roomDetails.data?.checkOutHour}
            />
            <LayoutBookingOverviewDivider />
            <LayoutBookingOverviewSection label={t('booking-overview.accommodation')}>
              <Typography variant='bodyM'>
                {roomDetails.isSuccess ? createRoomNameString(roomDetails.data) : accommodation.data.room_type}
              </Typography>
              <Icons>
                <IconWithLabel>
                  <FontAwesomeIcon icon={ICONS.farMoonStars} />
                  <Typography variant='titleS'>{accommodation.data.days}</Typography>
                </IconWithLabel>
                <IconWithLabel>
                  <FontAwesomeIcon icon={ICONS.farFamilyPants} />
                  <Typography variant='titleS'>{accommodation.data.adults + accommodation.data.children}</Typography>
                </IconWithLabel>
              </Icons>
            </LayoutBookingOverviewSection>
            <LayoutBookingOverviewDivider />
            <LayoutBookingOverviewSection label={t('booking-overview.booking-status')}>
              <Typography variant='bodyM'>
                {t(accommodation.data.status, { ns: LocaleNamespace.AccommodationStatus })}
              </Typography>
            </LayoutBookingOverviewSection>
            <LayoutBookingOverviewSection label={t('booking-overview.payment-status')}>
              <Typography variant='bodyM'>
                {isOwnerUsage(accommodation.data)
                  ? t('booking-overview.owner-payment')
                  : t(accommodation.data.payment_status, { ns: LocaleNamespace.PaymentStatus })}
              </Typography>
            </LayoutBookingOverviewSection>
            {destinationDetails.isSuccess && destinationDetailsLink && (
              <LayoutBookingOverviewSection label={t('booking-overview.arrival-info')}>
                <LinkWithIcon
                  to={destinationDetailsLink.to}
                  key={destinationDetailsLink.label}
                  target='_blank'
                  rel='external'
                >
                  <Typography variant='textLinkM'>{destinationDetailsLink.label}</Typography>
                  <FontAwesomeIcon style={{ marginLeft: unit(2) }} icon={ICONS.fasLocationArrow} />
                </LinkWithIcon>
              </LayoutBookingOverviewSection>
            )}
            <Button component={LinkUnstyled} to={to(Path.BookingDetails)} variant='contained' color='primary'>
              {t('booking-overview.see-booking-details')}
            </Button>
          </LayoutBookingOverviewContent>
        </LayoutBookingOverview>
      </>
    )
  }

  return <BookingOverviewLoading />
}

const Icons = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${unit(5.5)};
  margin-top: ${unit(1)};
`

const IconWithLabel = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${unit(1.5)};
`

const LinkWithIcon = styled(LinkUnstyled)`
  display: inline-table;
  align-items: center;
  gap: ${unit(1)};
  cursor: pointer;
  align-self: flex-start;
  -webkit-tap-highlight-color: transparent;

  span,
  svg {
    color: ${COLORS.linknega[500]};
  }
`
