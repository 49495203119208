import type { LinkExternalDto } from '@hcr/api/optimizely'
import type { Link } from '@hcr/ui'

export const createLinkGoogleMaps = (dto: LinkExternalDto): Link => {
  const encodedAddress = encodeURIComponent(dto.url)
  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`

  return {
    isExternal: true,
    label: dto.label,
    to: googleMapsUrl,
  }
}
