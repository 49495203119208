import { z } from 'zod'

export const SegmentNameDtoSchema = z.union([
  z.literal('business-travel-domestic'),
  z.literal('business-travel-international'),
  z.literal('free-apartment-rci-banking'),
  z.literal('gift-card-web-shop'),
  z.literal('groups'),
  z.literal('ii-exchange'),
  z.literal('ii-exchange-np'),
  z.literal('internal-exchange'),
  z.literal('internal-exchange-arrival'),
  z.literal('maintenance'),
  z.literal('omistajan-oma-käyttö-villas'),
  z.literal('owner-rental'),
  z.literal('owner-usage'),
  z.literal('owner-usage-points'),
  z.literal('points-banking'),
  z.literal('points-exchange'),
  z.literal('points-exchange-np'),
  z.literal('rci-exchange'),
  z.literal('rci-exchange-np'),
  z.literal('rci-ii-exchange-banking'),
  z.literal('spi-siirrot-koy-vuokraa'),
  z.literal('spi-siirrot-pistepalautus'),
  z.literal('tour-operator-domestic'),
  z.literal('tour-operator-international'),
  z.literal('transient-domestic'),
  z.literal('transient-international'),
  z.literal('unconfirmed-owner-usage'),
])

export type SegmentNameDto = z.infer<typeof SegmentNameDtoSchema>

export const isSegmentNameDto = (x: unknown): x is SegmentNameDto => SegmentNameDtoSchema.safeParse(x).success
