import { Stack, Typography } from '@mui/material'
import type { FC, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { type To } from 'react-router-dom'

import { unit } from '@hcr/ui'

import { LayoutBackNavigateCoupon } from '../layout-back-navigate-coupon'

interface LayoutCouponDetailsActivationProps extends PropsWithChildren {
  backNavigationPath: To
  couponImageUrl: string
}

export const LayoutCouponDetailsActivation: FC<LayoutCouponDetailsActivationProps> = ({
  backNavigationPath,
  children,
  couponImageUrl,
}) => {
  const { t } = useTranslation()

  return (
    <LayoutBackNavigateCoupon to={backNavigationPath} headerImageUrl={couponImageUrl}>
      <Stack sx={{ gap: unit(3), alignItems: 'center', paddingTop: unit(7), textAlign: 'center' }}>
        <Typography variant='headlineS'>{t('coupons.are-you-sure-you-want-to-use-your-coupon?')}</Typography>
      </Stack>

      <Stack sx={{ gap: unit(3), padding: unit(4), alignItems: 'center', textAlign: 'center' }}>
        <Typography variant='bodyM'>{t('coupons.coupon-can-be-used-only-once')}</Typography>
      </Stack>

      {children}
    </LayoutBackNavigateCoupon>
  )
}
