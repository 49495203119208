import type { z } from 'zod'

import { StringDtoSchema } from '@hcr/api/shared'

import { CouponContentDtoSchema } from '../components'

export const CouponDetailsDtoSchema = CouponContentDtoSchema.omit({ id: true }).extend({
  description: StringDtoSchema,
  imageUrl: StringDtoSchema.url(),
})

export type CouponDetailsDto = z.infer<typeof CouponDetailsDtoSchema>

export const isCouponDetailsDto = (x: unknown): x is CouponDetailsDto => CouponDetailsDtoSchema.safeParse(x).success
