import { Box, Skeleton, Stack, styled } from '@mui/material'
import type { FC } from 'react'

import { COLORS, unit, serratedEdgeVerticalCss } from '@hcr/ui'

export const TicketPreviewLoading: FC = () => {
  return (
    <Container>
      <Header>
        <Stack>
          <Skeleton width={unit(20)} height={unit(5)} />
          <Skeleton width={unit(30)} height={unit(6.5)} variant='rectangular' />
        </Stack>
        <Skeleton width={unit(25)} height={unit(5.5)} variant='rectangular' />
      </Header>
      <Box>
        <Skeleton width={unit(25)} height={unit(6)} />
        <Skeleton width={unit(50)} height={unit(5)} />
      </Box>
    </Container>
  )
}

const Container = styled(Stack)`
  background-color: ${COLORS.white};
  padding: ${unit(4)};
  padding-right: ${unit(16.875)};
  gap: ${unit(6)};
  width: 100%;

  ${serratedEdgeVerticalCss};
`

const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: ${unit(2)};
`
