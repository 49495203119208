const black = '#000000'

const burgundi = {
  300: '#E0D5D7',
  400: '#9B7F85',
  500: '#632E38',
  tint: '#88525B',
}

const green = {
  500: '#5EFFA5',
}

const grey = {
  100: '#EDEDED',
  600: '#7C7777',
  700: '#5A5757',
  800: '#3C3939',
}

const hiekka = {
  500: '#E7DFDA',
  700: '#D1C5BD',
}

const kaarna = {
  500: '#7E5A51',
  '60%': '#927972',
}

const linknega = {
  500: '#FF9D9D',
}

const maitokahvi = {
  500: '#7E5A51',
}

const puuteri = {
  300: '#E9DAD7',
  400: '#D5B8B1',
  500: '#D2ABA2',
}

const red = {
  bright: '#FF3C3C',
  dark: '#4A232A',
}

const ruoho = {
  400: '#ACBDA4',
  500: '#9BA794',
  '20%': '#EBEDEA',
  '60%': '#CDD7C8',
}

const sammal = {
  500: '#2C3E34',
}

const semantic = {
  disabled: '#B5AFB0',
  divider: '#BAB4B0',
  error: '#D32F2F',
  errorBody: '#FFDCDC',
  floatingLabel: '#6D1022',
  info: '#183DFF',
  infoBody: '#DFE4FF',
  infoBodyDark: '#D0D8FF',
  success: '#247328',
  successBody: '#D9F2DA',
  warning: '#ED6C02',
  warningBody: '#FFF2D8',
}

const tummakaarna = {
  500: '#48312C',
}

const vaalea = {
  500: '#D9CDC5',
  600: '#D1C5BD',
  700: '#C6B5AA',
  800: '#B1A195',
  '60%': '#F1ECE9',
}

const white = '#FFFFFF'

export const COLORS = {
  black,
  burgundi,
  green,
  grey,
  hiekka,
  kaarna,
  linknega,
  maitokahvi,
  puuteri,
  red,
  ruoho,
  sammal,
  semantic,
  tummakaarna,
  vaalea,
  white,
}
