import { type FC } from 'react'
import { useParams } from 'react-router-dom'

import { find, hasPropertyValue, isNotNull, isNotUndefined, isUndefined } from '@hcr/utils'

import { useCouponsQuery, useIdToken } from '../../../hooks'
import type { CouponDetailsPathParams } from '../../../models'
import { Path } from '../../../models'
import { to } from '../../../utils'
import { LayoutNavigationBackError404, LayoutNavigationBackLoading } from '../../common'

import { CouponDetailsInactive } from './CouponDetailsInactive'
import { CouponDetailsUsed } from './CouponDetailsUsed'

const BACK_NAVIGATION_PATH = to(Path.Home)

export const CouponDetails: FC = () => {
  const idToken = useIdToken()
  const params = useParams<CouponDetailsPathParams>()

  const coupon = useCouponsQuery(
    { idToken: String(idToken) },
    {
      select: find(hasPropertyValue('coupon_id', params.couponId)),
      enabled: isNotNull(idToken) && isNotUndefined(params.couponId),
    }
  )

  if (isUndefined(params.couponId) || coupon.isError || (coupon.isSuccess && isUndefined(coupon.data))) {
    return <LayoutNavigationBackError404 to={BACK_NAVIGATION_PATH} />
  }

  if (coupon.isSuccess && isNotUndefined(coupon.data)) {
    if (coupon.data.is_used) {
      return <CouponDetailsUsed couponId={params.couponId} contentId={String(params.contentId)} />
    } else {
      return <CouponDetailsInactive couponId={params.couponId} contentId={String(params.contentId)} />
    }
  }

  return <LayoutNavigationBackLoading to={BACK_NAVIGATION_PATH} />
}
