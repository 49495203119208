import { z } from 'zod'

import { Int32DtoSchema, StringDtoSchema } from '@hcr/api/shared'

export const CouponContentDtoSchema = z.strictObject({
  id: Int32DtoSchema,
  caption: StringDtoSchema,
  couponType: StringDtoSchema,
  heading: StringDtoSchema,
  iconUrl: StringDtoSchema.url(),
  toBeActivated: z.boolean(),
})

export type CouponContentDto = z.infer<typeof CouponContentDtoSchema>

export const isCouponContentDto = (x: unknown): x is CouponContentDto => CouponContentDtoSchema.safeParse(x).success
