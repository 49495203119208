import { Stack } from '@mui/material'
import type { FC } from 'react'

import { CouponLoading, unit } from '@hcr/ui'

export const CouponsLoading: FC = () => {
  return (
    <Stack gap={unit(3)}>
      <CouponLoading />
      <CouponLoading />
    </Stack>
  )
}
