import { z } from 'zod'

import { BooleanDtoSchema, StringDtoSchema } from '@hcr/api/shared'

export const CouponDtoSchema = z.strictObject({
  coupon_id: StringDtoSchema,
  valid_from_date: StringDtoSchema.datetime(),
  valid_to_date: StringDtoSchema.datetime(),
  coupon_type: StringDtoSchema,
  is_used: BooleanDtoSchema,
  used_date: StringDtoSchema.datetime().nullable(),
})

export type CouponDto = z.infer<typeof CouponDtoSchema>

export const isCouponDto = (x: unknown): x is CouponDto => CouponDtoSchema.safeParse(x).success
