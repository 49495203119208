import type { ReactNode } from 'react'

import type { CouponDto } from '@hcr/api/consumer'

import { CouponPreview } from './CouponPreview'

export const createCouponPreview = () => {
  return (dto: CouponDto): ReactNode => {
    return <CouponPreview key={dto.coupon_id} coupon={dto} />
  }
}
