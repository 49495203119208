import type { FC } from 'react'

import { find, isNotUndefined, hasPropertyValue, isNotNull, isUndefined } from '@hcr/utils'

import { useIdToken, useCouponsQuery, useCouponDetailsQuery } from '../../../hooks'
import { Path } from '../../../models'
import {
  to,
  isNotUndefinedOrEmptyPathParam,
  createCouponValidityString,
  createCouponUsedValidityString,
} from '../../../utils'
import { LayoutNavigationBackError500, LayoutNavigationBackLoading } from '../../common'

import { LayoutCouponDetailsUsed } from './common'

interface CouponDetailsUsedProps {
  contentId: string
  couponId: string
}

const BACK_NAVIGATION_PATH = to(Path.Home)

export const CouponDetailsUsed: FC<CouponDetailsUsedProps> = ({ couponId, contentId }) => {
  const idToken = useIdToken()

  const coupon = useCouponsQuery(
    { idToken: String(idToken) },
    {
      select: find(hasPropertyValue('coupon_id', couponId)),
      enabled: isNotNull(idToken),
    }
  )

  const couponDetails = useCouponDetailsQuery(
    { couponId: String(contentId) },
    {
      enabled: isNotUndefinedOrEmptyPathParam(contentId) && isNotUndefined(contentId),
    }
  )

  if (coupon.isError || (coupon.isSuccess && isUndefined(coupon.data))) {
    return <LayoutNavigationBackError500 to={BACK_NAVIGATION_PATH} />
  }

  if (coupon.isSuccess && isNotUndefined(coupon.data) && couponDetails.isSuccess) {
    if (isNotUndefined(couponDetails.data)) {
      const { caption, description, heading, iconUrl, imageUrl, toBeActivated } = couponDetails.data

      return (
        <LayoutCouponDetailsUsed
          backNavigationPath={BACK_NAVIGATION_PATH}
          caption={caption}
          couponIconUrl={iconUrl}
          couponImageUrl={imageUrl}
          description={description}
          heading={heading}
          toBeActivated={toBeActivated}
          isUsed={coupon.data.is_used}
          validity={createCouponValidityString(coupon.data)}
          validityUsed={coupon.data.used_date ? createCouponUsedValidityString(coupon.data) : undefined}
        />
      )
    }

    return <LayoutNavigationBackError500 to={BACK_NAVIGATION_PATH} />
  }

  return <LayoutNavigationBackLoading to={BACK_NAVIGATION_PATH} />
}
