export enum Mocks {
  HasAccommodations = 'hasAccommodations',
  HasActiveContracts = 'hasActiveContracts',
  HasActivities = 'hasActivities',
  HasBenefits = 'hasBenefits',
  HasCoupons = 'hasCoupons',
  HasHotelRoom = 'hasHotelRoom',
  HasSingleTickets = 'hasSingleTickets',
  Holding = 'holding',
  IsGoldOwner = 'isGoldOwner',
  IsVillasOwner = 'isVillasOwner',
}
