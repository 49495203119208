import { Box, Stack, styled, Typography } from '@mui/material'
import type { FC, PropsWithChildren, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import type { To } from 'react-router-dom'

import { Chip, COLORS, serratedEdgeHorizontalCss, unit } from '@hcr/ui'
import { isNotUndefined } from '@hcr/utils'

import { LocaleNamespace } from '../../../../../models'
import { LayoutNavigationBack } from '../../../../common'
import type { AdditionalInfoListItem } from '../additional-info-list'
import { AdditionalInfoList } from '../additional-info-list'
import { TicketDivider } from '../ticket-divider'

interface LayoutTicketDetailsInactiveProps extends PropsWithChildren {
  additionalInfo: AdditionalInfoListItem[]
  backNavigationPath: To
  cta: ReactNode
  guests?: string
  guestsLabel?: string
  name: string
  type: string
  validity?: ReactNode
}

export const LayoutTicketDetailsInactive: FC<LayoutTicketDetailsInactiveProps> = ({
  additionalInfo,
  backNavigationPath,
  children,
  cta,
  guests,
  guestsLabel,
  name,
  type,
  validity,
}) => {
  const { t } = useTranslation()

  return (
    <LayoutNavigationBack to={backNavigationPath} arrowVariant='text'>
      <Container>
        <Content>
          <Header>
            <Name>
              <Stack gap={unit(1)}>
                <Typography variant='labelM' color={COLORS.grey[600]}>
                  {type}
                </Typography>
                <Typography variant='headlineM'>{name}</Typography>
              </Stack>
              <Box>
                <Chip text={t('Inactive', { ns: LocaleNamespace.TicketStatus })} variant='Inactive' />
              </Box>
            </Name>
            <Stack gap={unit(1)}>
              {isNotUndefined(guestsLabel) && <Typography variant='titleS'>{guestsLabel}</Typography>}
              {isNotUndefined(guests) && <Typography variant='titleS'>{guests}</Typography>}
              {isNotUndefined(validity) && <Typography variant='bodyM'>{validity}</Typography>}
            </Stack>
          </Header>
          {children && <TicketDivider />}
          {children}
        </Content>
        <Footer>
          <AdditionalInfoList items={additionalInfo} />
          {cta}
        </Footer>
      </Container>
    </LayoutNavigationBack>
  )
}

const Container = styled(Box)`
  overflow-x: clip;
`

const Content = styled(Stack)`
  padding: ${unit(24)} ${unit(7)} ${unit(7.5)};
  background-color: ${COLORS.white};

  ${serratedEdgeHorizontalCss}
`

const Header = styled(Stack)`
  gap: ${unit(8.5)};
  padding-bottom: ${unit(8.5)};
`

const Name = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: ${unit(2)};
`

const Footer = styled(Stack)`
  gap: ${unit(5)};
  padding: ${unit(5)} ${unit(7)};
`
