import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from '@mui/material'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ICONS, unit } from '@hcr/ui'
import { filter, flow, isEmpty, isNotEmpty, isNotNull, isNotUndefined } from '@hcr/utils'

import { useCouponsContentQuery, useCouponsQuery, useIdToken } from '../../../hooks'

import { createCouponPreview } from './common'
import { CouponsLoading } from './CouponsLoading'
import { prepareCouponPreviewsData } from './utils'

export const Coupons = () => {
  const idToken = useIdToken()
  const { t } = useTranslation()
  const [showAll, setShowAll] = useState(false)

  const coupons = useCouponsQuery(
    { idToken: String(idToken) },
    {
      enabled: isNotNull(idToken),
    }
  )

  const hasCoupons = coupons.isSuccess && isNotUndefined(coupons.data) && isNotEmpty(coupons.data)

  const couponsContent = useCouponsContentQuery({
    select: flow(filter(({ couponType }) => (coupons.data || []).some(coupon => coupon.coupon_type === couponType))),
    enabled: isNotNull(idToken) && hasCoupons,
  })

  const couponsPreview = useMemo(() => createCouponPreview(), [])

  const couponsPreviewData = useMemo(
    () => prepareCouponPreviewsData([coupons.data].filter(isNotUndefined)),
    [coupons.data]
  )

  if (!hasCoupons) {
    return null
  }

  if (coupons.isPending || couponsContent.isPending) {
    return <CouponsLoading />
  }

  if (couponsContent.isError || (couponsContent.isSuccess && isEmpty(couponsContent.data))) {
    return null
  }

  const couponsWithContent = couponsPreviewData.filter(coupon =>
    couponsContent.data.some(content => content.couponType === coupon.coupon_type)
  )

  const displayedCoupons = showAll ? couponsWithContent : couponsWithContent.slice(0, 2)

  return (
    <>
      {displayedCoupons.map(couponsPreview)}

      {couponsWithContent.length > 2 && (
        <Typography variant='textLinkM' onClick={() => setShowAll(value => !value)} textAlign='center'>
          {showAll ? t('coupons.show-less') : t('coupons.show-more')}{' '}
          <FontAwesomeIcon style={{ marginLeft: unit(2) }} icon={showAll ? ICONS.farArrowUp : ICONS.farArrowDown} />
        </Typography>
      )}
    </>
  )
}
