import { isCouponDto, type CouponDto } from '@hcr/api/consumer'
import { flat, flow, isUndefined, reduce, sort } from '@hcr/utils'

export const prepareCouponPreviewsData = flow(
  flat<CouponDto[]>(),
  sort([
    { asc: ({ is_used }) => (is_used ? 1 : 0) },
    {
      asc: coupon => coupon.valid_to_date,
    },
  ]),
  reduce((acc, curr) => {
    const [prev] = acc.slice(-1)

    if (isUndefined(prev) || isCouponDto(prev) || isCouponDto(curr)) {
      return [...acc, curr]
    }
    return [...acc, curr]
  }, [] as Array<CouponDto>)
)
