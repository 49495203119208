import { t } from 'i18next'

import type { CouponDto } from '@hcr/api/consumer'

import { DateFormat, LocaleNamespace } from '../../models'
import { dateFormatter } from '../dates'

interface CouponValidity {
  date: string
  label: string
  used?: {
    date: string
    label: string
  }
}

export const createCouponValidity = (coupon: CouponDto): CouponValidity => ({
  date: dateFormatter.formatDateRange({
    from: coupon.valid_from_date,
    fromFormat: DateFormat.Standard,
    to: coupon.valid_to_date,
    toFormat: DateFormat.Standard,
  }),
  label: t('Valid', { ns: LocaleNamespace.CouponStatus }),
})
