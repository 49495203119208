import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { sendJson } from '@hcr/api/shared'

import type { ProblemDetailsDto, CouponActivationPayloadDto } from '../../dtos'
import type { AuthorizedRequestParams } from '../../models'
import { createAuthorizationHeader } from '../../utils'

export const createCouponActivationMutation =
  (baseUrl: string) =>
  (
    { idToken }: AuthorizedRequestParams,
    options?: Partial<UseMutationOptions<void, ProblemDetailsDto, CouponActivationPayloadDto>>
  ) =>
    useMutation<void, ProblemDetailsDto, CouponActivationPayloadDto>({
      ...options,
      mutationFn: ({ couponId }) =>
        sendJson({
          headers: createAuthorizationHeader(idToken),
          method: 'PUT',
          url: `${baseUrl}/coupons/${couponId}/activate`,
        }),
    })
