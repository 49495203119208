import { t } from 'i18next'

import type { CouponDto } from '@hcr/api/consumer'
import type { CouponContentDto } from '@hcr/api/optimizely'

import { LocaleNamespace, type CouponStatus } from '../../models'

type Coupon = Pick<CouponDto, 'is_used'> & Pick<CouponContentDto, 'toBeActivated'>

export const createCouponStatus = (coupon: Coupon): CouponStatus => ({
  label: coupon.is_used
    ? t('Used', { ns: LocaleNamespace.CouponStatus })
    : coupon.toBeActivated
      ? t('Inactive', { ns: LocaleNamespace.CouponStatus })
      : t('Valid', { ns: LocaleNamespace.CouponStatus }),
  value: coupon.is_used ? 'Used' : coupon.toBeActivated ? 'Inactive' : 'Valid',
})
