import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import type { CouponDto } from '@hcr/api/consumer'
import { Coupon } from '@hcr/ui'
import { find, flow, isNotUndefined } from '@hcr/utils'

import { useCouponsContentQuery } from '../../../../../hooks'
import { Path } from '../../../../../models'
import { createCouponStatus, isCouponOf, to } from '../../../../../utils'

interface CouponPreviewProps {
  coupon: CouponDto
}

export const CouponPreview: FC<CouponPreviewProps> = ({ coupon }) => {
  const { t } = useTranslation()

  const couponContent = useCouponsContentQuery({
    select: flow(find(isCouponOf(coupon))),
  })

  if (couponContent.isSuccess && isNotUndefined(couponContent.data)) {
    const { heading, iconUrl, toBeActivated, caption, id } = couponContent.data

    return (
      <Coupon
        caption={caption}
        title={heading}
        key={coupon.coupon_id}
        icon={iconUrl}
        link={{
          title: t('coupons.open'),
          label: t('coupons.open'),
          to: to([Path.CouponDetails, { couponId: coupon.coupon_id, contentId: String(id) }]),
        }}
        status={createCouponStatus({ toBeActivated, is_used: coupon.is_used })}
      />
    )
  }

  return null
}
