import { Box, Skeleton, Stack } from '@mui/material'
import type { FC } from 'react'

import { COLORS } from '../../constants'
import { serratedEdgeVerticalCss } from '../../styles'
import { unit } from '../../utils'

export const CouponLoading: FC = () => {
  return (
    <Stack
      gap={unit(3)}
      padding={unit(6)}
      bgcolor={COLORS.ruoho['60%']}
      alignItems='flex-start'
      justifyContent='flex-start'
      minHeight={unit(42)}
      css={serratedEdgeVerticalCss}
    >
      <Box width='100%' display='flex' justifyContent='space-between'>
        <Skeleton width='30%' height={unit(6)} />
        <Skeleton width='20%' height={unit(6)} />
      </Box>
      <Stack width='100%' display='flex' flexDirection='row' gap={unit(3)}>
        <Box width='80%'>
          <Skeleton width='80%' height={unit(7)} />
          <Skeleton width='70%' height={unit(7)} />
          <Skeleton width='20%' height={unit(5)} />
        </Box>
        <Box width='20%'>
          <Skeleton width='100%' height={unit(18)} variant='rectangular' />
        </Box>
      </Stack>
    </Stack>
  )
}
